<script setup>
/******************************************************************************
 * Component: Checkbox
 *****************************************************************************/
import { useSlots, ref } from "vue";
defineProps({
    refId: {
        type: String,
        default: Math.random().toString(36).substring(2),
    },
    checkedInitially: {
        type: Boolean,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const slots = useSlots();
const checkboxStatus = ref(false);
const emit = defineEmits(["change"]);
const onChange = () => emit("change", checkboxStatus.value);
</script>

<template>
    <div class="checkbox__wrapper">
        <label class="checkbox__label" :for="refId">
            <input
                :id="refId"
                v-model="checkboxStatus"
                type="checkbox"
                :name="refId"
                @change="onChange"
            />
            <span class="checkbox__inner"></span>
        </label>
        <label
            v-if="slots.default"
            class="checkbox__text"
            :for="refId"
            :class="{ checked: checkboxStatus, disabled }"
            ><slot></slot
        ></label>
    </div>
</template>

<style lang="scss">
$size: 28px;

/* stylelint-disable selector-class-pattern */
.checkbox {
    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__label {
        display: block;
        position: relative;

        width: $size;
        height: $size;
        flex: 0 0 $size;

        cursor: pointer;

        line-height: $size;

        /* hide checkbox visually */
        input {
            position: absolute;
            opacity: 0;

            cursor: pointer;
        }
    }

    &__inner {
        position: absolute;
        top: 0;
        left: 0;

        width: $size;
        height: $size;
        border: #{$size * 0.125} solid get($client, "checkboxBorder");

        background-color: get($client, "checkboxBg");
        border-radius: get($sizes, "radius");

        transition: border-color get($timings, "fast") ease-out,
            opacity get($timings, "fast") ease-out,
            transform get($timings, "fast") ease-out,
            background-color get($timings, "fast") ease-out;
    }

    &__label input:checked ~ .checkbox__inner {
        opacity: 1;

        border: #{$size * 0.125} solid palette("grey", "fog");
        transform: rotate(0deg) scale(1);

        background-color: get($client, "checkboxCheckedBg");
    }

    /* disabled checkbox */
    &__label input:disabled ~ .checkbox__inner {
        opacity: 0.5;

        cursor: not-allowed;
    }

    /* check mark */
    &__label .checkbox__inner::after {
        position: absolute;
        top: #{$size * 0.5};
        left: #{$size * 0.5};
        opacity: 1;

        width: 0;
        height: 0;
        border: solid get($client, "checkboxCheckedBorder");
        border-width: 0 #{$size * 0.125} #{$size * 0.125} 0;
        transform: rotate(0deg) scale(0);

        content: "";

        transition: transform get($timings, "fast") ease-out,
            width get($timings, "fast") ease-out,
            height get($timings, "fast") ease-out,
            top get($timings, "fast") ease-out,
            left get($timings, "fast") ease-out,
            border-width get($timings, "fast") ease-out;
    }

    &__label input:checked ~ .checkbox__inner::after {
        top: -1px;
        left: #{$size * 0.2};

        width: #{$size * 0.25};
        height: #{$size * 0.5};
        border-width: 0 #{$size * 0.125} #{$size * 0.125} 0;
        transform: rotate(45deg) scale(1);

        background-color: transparent;
        border-radius: 0;
    }

    /* For Ripple Effect */
    &__label .checkbox__inner::before {
        position: absolute;
        top: -#{$size * 0.35};
        left: -#{$size * 0.35};

        width: 0;
        height: 0;
        border: #{$size * 0.75} solid palette("grey", "iron");
        transform: scale(0);

        border-radius: 50%;

        content: "";

        transition: all get($timings, "quick") ease-out;
    }

    &__label input:checked ~ .checkbox__inner::before {
        top: -#{$size};
        left: -#{$size};
        z-index: 999;
        opacity: 0;

        width: $size;
        height: $size;
        transform: scale(3);
    }

    /* text label */
    &__text {
        display: block;

        padding-left: 8px;
        flex: 1;

        color: palette("text", "base");
        cursor: pointer;

        @include respond-to("medium") {
            padding-left: 16px;
        }

        &.checked {
            color: palette("brand1", "color");
        }

        &.disabled {
            cursor: not-allowed;
        }
    }
}

/* stylelint-enable selector-class-pattern */
</style>
