<script setup>
/******************************************************************************
 * Component: Form nav row: bottom row with navigation buttons
 *****************************************************************************/
import { useSlots } from "vue";
defineProps({
    top: {
        type: Number,
        default: 0,
    },
    bottom: {
        type: Number,
        default: 0,
    },
});
const slots = useSlots();
</script>

<template>
    <div class="nav-row">
        <div v-if="slots.back" class="back">
            <slot name="back" />
        </div>
        <div v-if="slots.forward" class="forward">
            <slot name="forward" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.nav-row {
    display: flex;
    justify-content: space-between;

    margin-top: calc(v-bind(top) * get($sizes, "base"));
    margin-bottom: calc(v-bind(bottom) * get($sizes, "base"));

    @include respond-to("medium") {
        margin-top: calc(v-bind(top) * get($sizes, "base") * 2);
        margin-bottom: calc(v-bind(bottom) * get($sizes, "base") * 2);
    }
}

.forward {
    margin-left: auto;
}

.back {
    margin-right: auto;
}
</style>
