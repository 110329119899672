<script setup>
/******************************************************************************
 * Vue entry component
 *****************************************************************************/
import AppHeader from "Components/AppHeader/AppHeader.vue";
import AppFooter from "Components/AppFooter/AppFooter.vue";
import PageMain from "Components/PageMain/PageMain.vue";
import { useAppStore } from "Stores/app";
import { onMounted, onUnmounted } from "vue";
import { isDevelopment } from "@/handlers/useEnvironment";
import { APP_NAME, APP_DESCRIPTION } from "./config";
const appStore = useAppStore();
const EVENTS = ["online", "offline", "load"]; // events that should be listened to
/**
 * @function
 */
const updateOnlineStatus = () => {
    if (
        typeof window.navigator.onLine === "undefined" ||
        window.navigator.onLine
    ) {
        appStore.online = true;
    } else {
        appStore.online = false;
    }
};
/**
 * @function Vue Lifecycle hook
 */
onMounted(() => {
    EVENTS.forEach((event) =>
        window.addEventListener(event, updateOnlineStatus)
    );
    document.title = APP_NAME;
    document
        .querySelector("meta[name=description]")
        .setAttribute("content", APP_DESCRIPTION);
    document
        .querySelector("meta[name=apple-mobile-web-app-title]")
        .setAttribute("content", APP_NAME);
    document
        .querySelector("meta[name=application-name]")
        .setAttribute("content", APP_NAME);
    isDevelopment && console.log(`mounted App.vue, env=development`);
});
/**
 * @function Vue Lifecycle hook
 */
onUnmounted(() => {
    console.log("unmounted page header title");
    EVENTS.forEach((event) =>
        window.removeEventListener(event, updateOnlineStatus)
    );
});
</script>

<template>
    <app-header />
    <page-main />
    <app-footer />
</template>

<style lang="scss">
@import "normalize.css"; // https://github.com/necolas/normalize.css/
@import "Styles/framework";
@import "Styles/form";
@import "Styles/textlink";
@import "Styles/nomodule";
</style>
