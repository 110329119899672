<script setup>
/******************************************************************************
 * PageComponent: AuthForm
 *****************************************************************************/
import { computed } from "vue";
import FormRow from "Components/Form/FormRow.vue";
import FormNavRow from "Components/Form/FormNavRow.vue";
import FormButton from "Components/Form/FormButton.vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import FormRequiredHint from "Components/Form/FormRequiredHint.vue";
import FormGlobalErrorMessages from "Components/Form/FormGlobalErrorMessages.vue";
import { useAuthStore } from "Stores/auth";
import { useAppStore } from "Stores/app";
const auth = useAuthStore();
const appStore = useAppStore();
const customerNo = computed({
    get: () => auth.customerNo,
    set: (value) => {
        auth.customerNo = value;
    },
});
const meterNo = computed({
    get: () => auth.meterNo,
    set: (value) => {
        auth.meterNo = value;
    },
});
/**
 * @function checks if submit should be disabled
 * @returns {boolean} only returns false if customerNo and userNo have a length !== 0
 */
const submitDisabled = () =>
    customerNo.value.length === 0 ||
    meterNo.value.length === 0 ||
    appStore.requesting ||
    !appStore.online;
</script>

<template>
    <form @submit.prevent="auth.login(customerNo, meterNo)">
        <form-row
            :bottom="0.5"
            :required="true"
            label="Kundennummer"
            for-id="customerNo"
        >
            <template #input>
                <input
                    id="customerNo"
                    v-model="customerNo"
                    class="form-input form-input--has-addon"
                    type="text"
                    maxlength="14"
                />
                <aside class="form-input-addon">
                    <svg-icon name="customer" :size="1" />
                </aside>
            </template>
        </form-row>
        <form-row
            :required="true"
            label="Zählernummer (laut Rechnung)"
            for-id="meterNo"
        >
            <template #input>
                <input
                    id="meterNo"
                    v-model="meterNo"
                    class="form-input form-input--has-addon"
                    type="text"
                    maxlength="20"
                />
                <aside class="form-input-addon">
                    <svg-icon name="meter" :size="1" />
                </aside>
            </template>
        </form-row>
        <form-required-hint :top="1" />
        <form-global-error-messages
            v-if="auth.error"
            :error-text="auth.error"
            :top="1"
        />
        <form-nav-row :top="1">
            <template #forward>
                <form-button
                    text="Anmelden"
                    icon-position="right"
                    icon-name="forward"
                    importance="primary"
                    :disabled="submitDisabled()"
                />
            </template>
        </form-nav-row>
    </form>
</template>
