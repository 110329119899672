/******************************************************************************
 * store for global app status
 *****************************************************************************/
import { defineStore } from "pinia";

/**
 * @function store definition
 */
export const useAppStore = defineStore("app", {
    state: () => {
        return {
            online: window.navigator.onLine,
            requesting: false,
        };
    },
    actions: {},
});
