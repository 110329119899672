<script setup>
/******************************************************************************
 * PageComponent: ContactDataSubmission
 *****************************************************************************/
import PageSection from "Components/PageSection/PageSection.vue";
import ContactDataSubmissionCustomer from "./ContactDataSubmissionCustomer.vue";
import { useReadingStore } from "Stores/reading";
import { URLS } from "@/config";
const data = useReadingStore();
const customers = data.customers.filter((customer) => {
    const c = customer.contactSubmission;
    return c.email || c.phone || c.phoneMobile || c.fax;
});
</script>

<template>
    <section class="contact-data">
        <page-section hdl="Halten Sie Ihre Kontaktdaten aktuell!">
            <p>
                Wir haben offenbar nicht alle Kontaktdaten von Ihnen. Wenn Sie
                wünschen können Sie uns diese Daten mitteilen, wir kontaktieren
                Sie ggf. unter den angegebenen Kanälen zu Belieferungsthemen.
            </p>
            <p>Alle Angaben sind freiwillig.</p>
        </page-section>
        <ul class="customers">
            <contact-data-submission-customer
                v-for="customer in customers"
                :key="customer.customerId"
                :customer-id="customer.customerId"
                :client-id="customer.clientId"
            />
        </ul>
        <p>
            Wir behandeln alle Ihre persönlichen Daten nach unserer geltenden
            <a class="text-link" :href="URLS.privacy" target="_blank"
                >Datenschutzrichtlinie</a
            >.
        </p>
    </section>
</template>

<style lang="scss" scoped>
.contact-data {
    padding: #{get($sizes, "base")};
    margin-top: #{get($sizes, "base") * 1};

    background-color: get($client, "contactDataBg");
    border-radius: get($sizes, "radius");

    @include respond-to("medium") {
        margin-top: #{get($sizes, "base") * 2};
    }
}

.customers {
    display: flex;
    flex-direction: column;

    padding: 0;
    margin: #{get($sizes, "base") * 1} 0 0 0;
    gap: #{get($sizes, "base") * 1};

    list-style: none;

    @include respond-to("medium") {
        gap: #{get($sizes, "base") * 1.5};
    }
}
</style>
