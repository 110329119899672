<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk) implausibleReasonOther
 *****************************************************************************/
import { computed } from "vue";
import FormRow from "Components/Form/FormRow.vue";
import FormError from "Components/Form/FormError.vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import { LENGTH_IMPLAUSIBLE_REASON_OTHER } from "@/config";
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
});
const implausibleReasonOther = computed({
    get: () => data.getImplausibleReasonOther(props.meterRegisterId),
    set: (value) => {
        data.setImplausibleReasonOther(props.meterRegisterId, value);
    },
});
const remaining = () =>
    LENGTH_IMPLAUSIBLE_REASON_OTHER - implausibleReasonOther.value.length;
/**
 * @function on key up, check length.
 * @param {KeyboardEvent} event
 */
const onKeyDown = (event) => {
    const validMetaKeyCodes = [8, 9, 46, 37, 39];
    const validMetaKeys = [
        "Backspace",
        "Delete",
        "Tab",
        "ArrowLeft",
        "ArrowRight",
        "NumLock",
    ];
    if (event.key) {
        if (validMetaKeys.includes(event.key)) return; // early return so backspace etc is always possible
    } else if (event.keyCode) {
        if (validMetaKeyCodes.includes(event.key)) return; // early return so backspace etc is always possible
    }
    if (implausibleReasonOther.value.length >= LENGTH_IMPLAUSIBLE_REASON_OTHER)
        event.preventDefault();
};
</script>

<template>
    <form-row
        :top="0.5"
        :required="true"
        label="Sonstiger Grund"
        :for-id="`implausibleReasonOther-${meterRegisterId}`"
    >
        <template #texttop>
            <form-error
                v-if="implausibleReasonOther.length === 0"
                error-text="Bitte geben Sie den Sonstigen Grund an."
                :bottom="0.2"
            />
        </template>
        <template #input>
            <input
                :id="`implausibleReasonOther-${meterRegisterId}`"
                v-model="implausibleReasonOther"
                class="form-input form-input--has-addon"
                type="text"
                formnovalidate
                @keydown="onKeyDown"
            />
            <aside class="form-input-addon">
                <svg-icon name="edit" />
            </aside>
        </template>
        <template #text>
            <span class="remaining">
                Sie können noch <strong>{{ remaining() }}</strong> Zeichen
                eingeben.
            </span>
        </template>
    </form-row>
</template>

<style lang="scss" scoped>
.remaining {
    font-size: #{get($sizes, "base") - 2px};

    strong {
        color: get($client, "implausibleReasonCharsRemainingColor");

        font-weight: 500;
    }
}
</style>
