<script setup>
/******************************************************************************
 * PageComponent: global reading date
 *****************************************************************************/
import FormRow from "Components/Form/FormRow.vue";
import FormCalendar from "Components/Form/FormCalendar.vue";
import FormError from "Components/Form/FormError.vue";
import { useReadingStore } from "Stores/reading";
import { addDays, subDays } from "date-fns";
const data = useReadingStore();
const maxDate = addDays(new Date(), data.config.readingDaysMax);
const minDate = subDays(new Date(), data.config.readingDaysMin);
const emit = defineEmits(["change"]);
const onChange = (value) => emit("change", value);
defineProps({
    initialDate: {
        type: String,
        default: "",
    },
    forId: {
        type: String,
        required: true,
    },
    allowChange: {
        type: Boolean,
        default: true,
    },
});
</script>

<template>
    <form-row :top="0.5" :required="true" label="Ablesedatum" :for-id="forId">
        <template #input>
            <form-calendar
                v-if="allowChange"
                :upper-limit="maxDate"
                :lower-limit="minDate"
                :initial-date="initialDate"
                :for-id="forId"
                @change="onChange"
            />
            <input
                v-else
                class="form-input readonly"
                :value="initialDate"
                readonly
                aria-readonly="true"
            />
        </template>
        <template v-if="initialDate === ''" #text
            ><form-error
                error-text="Bitte wählen Sie das Ablesedatum aus."
                :top="0.25"
        /></template>
    </form-row>
</template>
