<script setup>
/******************************************************************************
 * PageComponent: Single Meter (= Zählpunkt)
 *****************************************************************************/
import SingleMeterHeader from "./SingleMeterHeader.vue";
import ReadingReason from "../DateAndReasonType/ReadingReason.vue";
import ReadingDate from "../DateAndReasonType/ReadingDate.vue";
import MeterRegister from "./MeterRegister.vue";
import { useReadingStore } from "Stores/reading";
import { isDevelopment } from "@/handlers/useEnvironment";
import { meterContainsDismountedRegister } from "@/handlers/useReading";
const data = useReadingStore();
const props = defineProps({
    meterNumber: {
        type: String,
        required: true,
    },
});
const onDateChanged = (value) => {
    data.readings = data.readings.map((reading) => {
        if (reading.meterNumber === props.meterNumber)
            reading.readingDate = value;
        return reading;
    });
    isDevelopment &&
        console.log(`changed date of ${props.meterNumber} to "${value}".`);
};
const onReasonChanged = (value) => {
    data.readings = data.readings.map((reading) => {
        if (reading.meterNumber === props.meterNumber)
            reading.readingReason = value;
        return reading;
    });
    isDevelopment &&
        console.log(`changed reason of ${props.meterNumber} to "${value}".`);
};
</script>

<template>
    <li class="meter">
        <single-meter-header :meter-number="meterNumber" />
        <reading-reason
            v-if="data.dateAndReasonType === 'individual'"
            :selected-reason="data.getIndividualReadingReason(meterNumber)"
            :for-id="`readingReason-${meterNumber}`"
            @change="onReasonChanged"
        />
        <reading-date
            v-if="data.dateAndReasonType === 'individual'"
            :initial-date="data.getIndividualReadingDate(meterNumber)"
            :for-id="`individualReadingDate-${meterNumber}`"
            :allow-change="!meterContainsDismountedRegister(meterNumber)"
            @change="onDateChanged"
        />
        <meter-register
            v-for="readingRegister of data.getReadingRegisters(meterNumber)"
            :key="readingRegister.meterRegisterId"
            :meter-register-id="readingRegister.meterRegisterId"
        />
    </li>
</template>

<style lang="scss" scoped>
.meter {
    padding: get($sizes, "border");
    border: get($sizes, "border") solid get($client, "meterBorderColor");

    border-radius: get($sizes, "radius");
}
</style>
