<script setup>
/******************************************************************************
 * PageComponent: MeterReading Meters
 *****************************************************************************/
import SingleMeter from "./SingleMeter.vue";
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const meters = data.readings
    .map((meter) => meter.meterNumber)
    .filter((value, index, self) => self.indexOf(value) === index);
</script>

<template>
    <ul class="meters">
        <single-meter
            v-for="meterNumber in meters"
            :key="meterNumber"
            :meter-number="meterNumber"
        />
    </ul>
</template>

<style lang="scss" scoped>
.meters {
    display: flex;
    flex-direction: column;

    padding: 0;
    margin: 0;
    gap: #{get($sizes, "base") * 1};

    list-style: none;

    @include respond-to("medium") {
        gap: #{get($sizes, "base") * 1.5};
    }
}
</style>
