<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk) image preview
 *****************************************************************************/
import FormButton from "Components/Form/FormButton.vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import { useReadingStore } from "Stores/reading";
import { useAppStore } from "Stores/app";
const data = useReadingStore();
const appStore = useAppStore();
const meterRegisterData = data.getMeterRegisterData(props.meterRegisterId);
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
    thumb: {
        type: String,
        required: true,
    },
});
const onClickDelete = () => {
    const imageProps = data.getImageProps(props.meterRegisterId);
    data.deleteImage(
        meterRegisterData.customerId,
        props.meterRegisterId,
        imageProps.filePath
    );
};
</script>

<template>
    <div class="image">
        <img
            class="thumbnail"
            :src="thumb"
            alt="Vorschau des hochgeladenen Bildes"
        />
        <nav class="actions">
            <label
                :for="`readingImage-${meterRegisterId}`"
                class="form-button low"
            >
                <svg-icon name="add_photo" />
                <span>Anderes Bild</span>
            </label>
            <form-button
                text="Bild löschen"
                icon-name="delete"
                icon-position="left"
                importance="low"
                :disabled="appStore.requesting"
                @click="onClickDelete"
            />
        </nav>
    </div>
</template>

<style lang="scss" scoped>
.image {
    display: flex;

    margin-top: #{get($sizes, "base") * 0.2};
    gap: #{get($sizes, "base") * 0.5};

    @include respond-to("medium") {
        margin-top: #{get($sizes, "base") * 0.4};
        gap: #{get($sizes, "base") * 1};
    }
}

.thumbnail {
    padding: #{get($sizes, "border") * 1};
    border: #{get($sizes, "border") * 1} solid get($client, "imageThumbBorderColor");

    border-radius: get($sizes, "radius");
}

.actions {
    display: flex;
    flex-direction: column;

    gap: #{get($sizes, "base") * 0.2};

    @include respond-to("medium") {
        gap: #{get($sizes, "base") * 0.5};
    }
}
</style>
