<script setup>
/******************************************************************************
 * Component: AppModal
 *****************************************************************************/
import { useSlots } from "vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
defineProps({
    title: {
        type: String,
        required: true,
    },
    closable: {
        type: Boolean,
        default: false,
    },
});
const slots = useSlots();
const emit = defineEmits(["close"]);
</script>

<template>
    <div class="modal">
        <div class="modal__backdrop">
            <div class="modal__dialog">
                <header class="modal__head">
                    <h1>{{ title }}</h1>
                    <button
                        v-if="closable"
                        class="close"
                        aria-label="Dialog schließen"
                        @click="emit('close')"
                    >
                        <svg-icon name="cancel" :size="2" />
                    </button>
                </header>
                <main class="modal__body">
                    <slot />
                </main>
                <nav v-if="slots.actions" class="modal__actions">
                    <slot name="actions" />
                </nav>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: get($z, "overlay");

    background: get($client, "modalBackdropBg");
    backdrop-filter: blur(8px);

    text-align: left;

    &__backdrop {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        width: 100vw;
        height: 100vh;
    }

    &__dialog {
        position: relative;

        width: 100%;
        max-width: calc(100% - #{get($sizes, "base") * 2});
        border: get($client, "modalBorder");
        /* stylelint-disable indentation */
        margin: #{get($sizes, "base")} #{get($sizes, "base")} 0 #{get(
                $sizes,
                "base"
            )};
        /* stylelint-enable indentation */

        background: palette("grey", "sunken");

        /* stylelint-disable color-function-notation */
        box-shadow: 0 0 15px rgba(palette("grey", "fog"), 0.2);
        /* stylelint-enable color-function-notation */

        @include respond-to("medium") {
            max-width: get($breakpoints, "small");
            margin-top: 20vh;
        }
    }

    &__head {
        padding: #{get($sizes, "base") * 0.5};
        border-bottom: get($client, "modalSeperatorBorder");

        background-color: get($client, "modalBg");

        > h1 {
            margin: 0;

            color: palette("brand1", "color");

            font-size: #{get($sizes, "base") * 1.5};
            font-weight: 400;
            line-height: 1.2;
        }

        .close {
            $inset: get($sizes, "border");
            position: absolute;
            top: -#{get($sizes, "base") * 1.5};
            right: -#{get($sizes, "base") * 1.5};

            padding: #{get($sizes, "base") * 0.3};
            border: get($client, "modalCloseButtonBorder");

            background: get($client, "modalCloseButtonBg");
            color: get($client, "modalCloseButtonColor");
            border-radius: get($sizes, "radius");
            box-shadow: inset #{$inset} #{$inset} #fff,
                inset -#{$inset} #{$inset} #fff, inset #{$inset} -#{$inset} #fff,
                inset -#{$inset} -#{$inset} #fff;

            cursor: pointer;

            transition: background-color get($timings, "fast") linear,
                color get($timings, "fast") linear;

            &:hover {
                background: color.adjust(
                    get($client, "modalCloseButtonBg"),
                    $lightness: -10%
                );
                color: color.adjust(
                    get($client, "modalCloseButtonColor"),
                    $lightness: -10%
                );
            }
        }

        @include respond-to("medium") {
            padding: #{get($sizes, "base") * 0.5} #{get($sizes, "base") * 1};
        }
    }

    &__body {
        overflow: auto;

        max-height: 60vh;
        padding: #{get($sizes, "base") * 0.5};

        background-color: get($client, "modalBg");

        @include respond-to("medium") {
            padding: #{get($sizes, "base") * 0.5} #{get($sizes, "base") * 1};
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: #{get($sizes, "base") * 0.5};
        border-top: get($client, "modalSeperatorBorder");

        @include respond-to("medium") {
            padding: #{get($sizes, "base") * 0.5} #{get($sizes, "base") * 1};
        }
    }
}
</style>
