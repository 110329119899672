<script setup>
/******************************************************************************
 * PageComponent: SuccessAnimation
 *****************************************************************************/
const props = defineProps({
    size: { type: Number, default: 128 },
});
const svgStyle = {
    width: props.size + "px",
    height: props.size + "px",
};
</script>

<template>
    <svg viewBox="0 0 100 100" :style="svgStyle">
        <circle
            class="circle"
            cx="50"
            cy="50"
            r="47.5"
            stroke-width="5"
            stroke-linecap="round"
        />
        <path
            class="checkmark"
            d="M 22,52 l 20,20 l 35,-35"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
        />
    </svg>
</template>

<style lang="scss" scoped>
svg {
    width: 100%;
    height: 100%;
}

.checkmark {
    stroke: palette("state", "success");
    stroke-dasharray: 100;
    stroke-dashoffset: 100;

    animation: dash #{get($timings, "portly")} ease-in-out forwards;
    animation-delay: 0.5s;
}

.circle {
    fill: color.adjust(palette("state", "success"), $lightness: 60%);
    stroke: palette("state", "success");
    stroke-dasharray: 300;
    stroke-dashoffset: 300;

    animation: dash #{get($timings, "portly")} ease-in-out forwards;
    animation-delay: 0.5s;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
</style>
