<script setup>
/******************************************************************************
 * Component: Radio button group
 *****************************************************************************/
defineProps({
    name: { type: String, required: true },
    radioButtons: { type: Array, required: true },
});
const emit = defineEmits(["change"]);
const onChange = (ev) => emit("change", ev);
</script>

<template>
    <ul role="radiogroup" class="radio-list" aria-label="Verfügbare Optionen">
        <li
            v-for="button in radioButtons"
            :key="button.value"
            class="list-item"
        >
            <label :for="`${name}_${button.value}`">
                <input
                    :id="`${name}_${button.value}`"
                    type="radio"
                    :name="name"
                    :value="button.value"
                    :checked="button.checked"
                    @change="onChange"
                />
                <span class="radio" />
                <span class="label">{{ button.label }}</span>
            </label>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
.radio-list {
    padding: 0;
    margin: 0;
    flex-grow: 1;

    list-style-type: none;
}

.list-item:not(:last-child) {
    margin-bottom: #{get($sizes, "base") * 0.25};
}

label {
    display: flex;
    align-items: center;

    padding: #{get($sizes, "base") * 0.25} v#{get($sizes, "base") * 0.4375};
    gap: #{get($sizes, "base") * 0.75};

    cursor: pointer;

    transition: background-color get($timings, "fast") linear,
        color get($timings, "fast") linear;

    @include respond-to("medium") {
        padding: #{get($sizes, "base") * 0.4375} #{get($sizes, "base") * 1};
    }

    &:hover {
        background-color: get($client, "formRadioLabelHoverBg");
        color: get($client, "formRadioLabelHoverColor");
    }
}

label > .radio {
    display: flex;
    align-items: center;
    justify-content: center;

    width: #{get($sizes, "base") * 1.5};
    height: #{get($sizes, "base") * 1.5};
    border: #{get($sizes, "border") * 2} solid get($client, "formRadioButtonBorder");

    background: #fff;
    border-radius: 50%;

    &::before {
        display: block;

        width: #{get($sizes, "base") * 0.625};
        height: #{get($sizes, "base") * 0.625};

        border-radius: 50%;

        content: " ";

        transition: background-color get($timings, "fast") linear;
    }
}

.label {
    transition: color get($timings, "fast") linear;
}

input {
    display: none;
}

input:checked {
    ~ .label {
        color: get($client, "formRadioButtonCheckedColor");
    }

    + .radio::before {
        background-color: get($client, "formRadioButtonCheckedBg");
    }
}
</style>
