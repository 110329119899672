import { FALLBACK_PRECOMMA_DIGITS } from "@/config";
import { useReadingStore } from "Stores/reading";
import { isDevelopment } from "@/handlers/useEnvironment";

/**
 * @function is the reading value required?
 * @returns {boolean}
 */
export const isReadingValueRequired = () => {
    const data = useReadingStore();
    let isRequired = true;
    if (data.config.allMetersRequired) return true;
    const meterNumbers = data.readings
        .map((meter) => meter.meterNumber)
        .filter((value, index, self) => self.indexOf(value) === index);
    meterNumbers.forEach((meterNumber) => {
        // get all readings with this meterNumber
        const readings = data.readings.filter(
            (reading) => reading.meterNumber === meterNumber
        );
        // get all readings with this meterNumber that do have a readingValue
        const readingsWithValues = data.readings.filter(
            (reading) =>
                reading.meterNumber === meterNumber &&
                reading.meterReadingValue.length > 0
        );
        // if both are identical, all registers of this meter have a readingValue => no error.
        if (readings.length === readingsWithValues.length) isRequired = false;
    });
    return isRequired;
};

/**
 * @function calculate if postcomma digits can be entered by user
 * @param {Boolean} enforce
 * @param {Number} postCommaDigits
 * @returns {boolean}
 */
export const meterUsesPostComma = (enforce, postCommaDigits) => {
    if (!enforce) return false;
    return postCommaDigits !== 0;
};

/**
 * @function check whether keydown event is valid or not
 * @param {KeyboardEvent} event
 * @param {Number} maxLength - fallback to config value for precomma digits
 * @param {Number} currentLength
 */
export const validateKeyDown = (event, maxLength, currentLength) => {
    if (!maxLength) maxLength = FALLBACK_PRECOMMA_DIGITS; // fallback value if maxLength = falsy
    const validKeyCodes = [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 105,
    ];
    const validMetaKeyCodes = [8, 9, 46, 37, 39];
    const validKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const validMetaKeys = [
        "Backspace",
        "Delete",
        "Tab",
        "ArrowLeft",
        "ArrowRight",
        "NumLock",
    ];
    // for browsers supporting event.key
    if (event.key) {
        if (validMetaKeys.includes(event.key)) return; // early return so backspace etc is always possible
        if (!validKeys.includes(event.key)) event.preventDefault();
    }
    // for browsers that still support event.keyCode
    else if (event.keyCode) {
        if (validMetaKeyCodes.includes(event.keyCode)) return; // early return so backspace etc is always possible
        if (!validKeyCodes.includes(event.keyCode)) event.preventDefault();
    }
    if (currentLength >= maxLength) event.preventDefault();
};

/**
 * @function calculate consumption with the correct number of postComma digits
 * @param {String} meterRegisterId
 * @returns {String}
 */
export const getConsumption = (meterRegisterId) => {
    const data = useReadingStore();
    const meterRegisterData = data.getMeterRegisterData(meterRegisterId);
    const readingValue = data.getReadingValue(meterRegisterId);
    if (!meterRegisterData.enforcePostComma)
        return (
            parseFloat(readingValue) - meterRegisterData.lastReadingValue
        ).toString();
    else
        return (readingValue - meterRegisterData.lastReadingValue).toFixed(
            meterRegisterData.postCommaDigits
        );
};

/**
 * @function calculate if a reading value is plausible.
 * @param {String} meterRegisterId
 * @returns {boolean}
 */
export const isReadingPlausible = (meterRegisterId) => {
    const data = useReadingStore();
    const meterRegisterData = data.getMeterRegisterData(meterRegisterId);
    const readingValue = data.getReadingValue(meterRegisterId);
    isDevelopment &&
        console.log(
            `checking plausibility of ${meterRegisterId} - readingValue=${readingValue}, lastReadingValue=${meterRegisterData.lastReadingValue}, min=${meterRegisterData.meterValueMin}, max=${meterRegisterData.meterValueMax}`
        );
    if (parseFloat(readingValue) < meterRegisterData.lastReadingValue) {
        isDevelopment &&
            console.log("readingValue < lastReadingValue => implausible");
        return false;
    }
    if (
        meterRegisterData.meterValueMin !== 0 &&
        (readingValue < meterRegisterData.meterValueMin ||
            readingValue > meterRegisterData.meterValueMax)
    ) {
        isDevelopment &&
            console.log("readingValue not between min and max => implausible");
        return false;
    }
    isDevelopment && console.log("=> plausible.");
    return true;
};

/**
 * @function check if reading value is >= lastReadingValue
 * @param meterRegisterId
 * @returns {boolean}
 */
export const isLastReadingOk = (meterRegisterId) => {
    const data = useReadingStore();
    const meterRegisterData = data.getMeterRegisterData(meterRegisterId);
    const readingValue = data.getReadingValue(meterRegisterId);
    return parseFloat(readingValue) >= meterRegisterData.lastReadingValue;
};

/**
 * @function check if reading value is within min..max
 * @param meterRegisterId
 * @returns {boolean}
 */
export const isMinMaxReadingOk = (meterRegisterId) => {
    const data = useReadingStore();
    const meterRegisterData = data.getMeterRegisterData(meterRegisterId);
    const readingValue = data.getReadingValue(meterRegisterId);
    return (
        meterRegisterData.meterValueMin === 0 ||
        (readingValue >= meterRegisterData.meterValueMin &&
            readingValue <= meterRegisterData.meterValueMax)
    );
};

/**
 * @function check if a meter contains registers that are dismounted
 * @param meterNumber
 * @returns {boolean}
 */
export const meterContainsDismountedRegister = (meterNumber) => {
    const data = useReadingStore();
    console.log(
        "checking dismounted registers",
        data.getMeterRegisters(meterNumber)
    );
    return (
        data.getMeterRegisters(meterNumber).filter((meter) => meter.dismounted)
            .length > 0
    );
};

/**
 * @function check if any meter register is dismounted
 * @returns {boolean}
 */
export const metersContainDismountedRegister = () => {
    const data = useReadingStore();
    return data.meters.filter((meter) => meter.dismounted).length > 0;
};

/**
 * @function check all meters and change readingDate to meterDismountDate if the
 * meter contains a dismounted register.
 * @returns {boolean}
 */
export const applyDismountedReadingDates = (readings) => {
    const data = useReadingStore();
    let modifiedReadings = readings;
    // get unique meterNumbers so we can loop the meters (not meterRegisters)
    const meterNumbers = readings
        .map((reading) => reading.meterNumber)
        .filter(
            (meterNumber, index, self) => self.indexOf(meterNumber) === index
        );
    meterNumbers.forEach((meterNumber) => {
        // meter contains dismounted register?
        if (meterContainsDismountedRegister(meterNumber)) {
            modifiedReadings = modifiedReadings.map((reading) => {
                if (reading.meterNumber === meterNumber) {
                    // change readingDate of that meter's registers to meterDismountDate
                    reading.readingDate = data.getMeterRegister(
                        reading.meterRegisterId
                    ).meterDismountDate;
                }
                return reading;
            });
        }
    });
    return modifiedReadings;
};
