/******************************************************************************
 * App entrypoint
 *****************************************************************************/
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import axios from "axios";
import App from "./App.vue";
import { router } from "@/router/index";
import { isDevelopment } from "@/handlers/useEnvironment";
isDevelopment && console.log("bootstrapping app");

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * mount VueJs app
 */
const pinia = createPinia();
const app = createApp(App);
// https://github.com/prazdevs/pinia-plugin-persistedstate
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(router);
app.mount("#app");
