<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk) reading value
 *****************************************************************************/
import FormRow from "Components/Form/FormRow.vue";
import MeterRegisterReadingValueSingle from "./MeterRegisterReadingValueSingle.vue";
import MeterRegisterReadingValueDouble from "./MeterRegisterReadingValueDouble.vue";
import MeterRegisterReadingRequirements from "./MeterRegisterReadingRequirements.vue";
import MeterReadingValueConsumption from "./MeterReadingValueConsumption.vue";
import { useReadingStore } from "Stores/reading";
import { isDevelopment } from "@/handlers/useEnvironment";
import {
    meterUsesPostComma,
    getConsumption,
    isReadingValueRequired,
} from "@/handlers/useReading";
const data = useReadingStore();
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
});
const meterRegisterData = data.getMeterRegisterData(props.meterRegisterId);
const consumption = () => getConsumption(props.meterRegisterId);
const onChange = (value) => {
    data.setReadingValue(props.meterRegisterId, value);
    isDevelopment &&
        console.log(
            `reading value of meter ${props.meterRegisterId} changed to ${value}`
        );
};
</script>

<template>
    <form-row
        :bottom="0.5"
        :required="isReadingValueRequired()"
        label="Zählerstand"
        :for-id="`meterReading-${meterRegisterId}`"
    >
        <template #texttop>
            <meter-register-reading-requirements
                v-if="
                    meterRegisterData.lastReadingValue ||
                    meterRegisterData.meterValueMin !== 0
                "
                :meter-register-id="meterRegisterId"
            />
        </template>
        <template #input>
            <meter-register-reading-value-double
                v-if="
                    meterUsesPostComma(
                        meterRegisterData.enforcePostComma,
                        meterRegisterData.postCommaDigits
                    )
                "
                :meter-register-id="meterRegisterId"
                @change="onChange"
            />
            <meter-register-reading-value-single
                v-else
                :meter-register-id="meterRegisterId"
                @change="onChange"
            />
        </template>
        <template #text>
            <meter-reading-value-consumption
                v-if="consumption() >= 0"
                :consumption="consumption()"
                :unit="meterRegisterData.meterUnit"
            />
        </template>
    </form-row>
</template>
