<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk)
 *****************************************************************************/
import { onMounted } from "vue";
import MeterRegisterInfo from "./MeterRegisterInfo.vue";
import MeterRegisterReadingValue from "./ReadingValue/MeterRegisterReadingValue.vue";
import ImplausibleReason from "./ImplausibleReason/ImplausibleReason.vue";
import ImplausibleReasonOther from "./ImplausibleReason/ImplausibleReasonOther.vue";
import MeterImage from "./MeterImage/MeterImage.vue";
import { isReadingPlausible } from "@/handlers/useReading";
import { useReadingStore } from "Stores/reading";
import { useAppStore } from "Stores/app";
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
});
const data = useReadingStore();
const appStore = useAppStore();
const showImplausibleReasonOther = () =>
    showImplausibleReason() &&
    data.getImplausibleReason(props.meterRegisterId) === "Sonstiges";
const showImplausibleReason = () => {
    const readingValue = data.getReadingValue(props.meterRegisterId);
    if (readingValue === "") return false; // return early if no reading value
    return !isReadingPlausible(props.meterRegisterId);
};
const showMeterImage = () => {
    if (!data.config.allowImageUpload || !appStore.online) return false;
    return !(
        data.config.hideImageUploadForPlausibleReadings &&
        isReadingPlausible(props.meterRegisterId)
    );
};
/**
 * @function Vue Lifecycle hook
 */
onMounted(() => {
    data.setImplausibleReason(props.meterRegisterId, "Sonstiges");
});
</script>

<template>
    <div class="meter-register">
        <meter-register-info :meter-register-id="meterRegisterId" />
        <meter-register-reading-value :meter-register-id="meterRegisterId" />
        <implausible-reason
            v-if="showImplausibleReason()"
            :meter-register-id="meterRegisterId"
        />
        <implausible-reason-other
            v-if="showImplausibleReasonOther()"
            :meter-register-id="meterRegisterId"
        />
        <meter-image
            v-if="showMeterImage()"
            :meter-register-id="meterRegisterId"
        />
    </div>
</template>

<style lang="scss" scoped>
$small: #{get($sizes, "base") * 0.25};
$normal: #{get($sizes, "base") * 0.75};
$large: #{get($sizes, "base") * 1.5};

.meter-register {
    margin: $large $small;

    &:not(:last-child) {
        padding-bottom: $large;
        border-bottom: #{get($sizes, "border") * 2} solid get($client, "meterRegisterBoderColor");

        @include respond-to("medium") {
            border-bottom: #{get($sizes, "border") * 1} solid get($client, "meterRegisterBoderColor");
        }
    }

    @include respond-to("medium") {
        margin: $large $normal;
    }
}
</style>
