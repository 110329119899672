<script setup>
/******************************************************************************
 * PageComponent: Meta Data reading reason select
 *****************************************************************************/
import FormRow from "Components/Form/FormRow.vue";
import FormSelect from "Components/Form/FormSelect.vue";
import FormError from "Components/Form/FormError.vue";
const options = [
    { label: "Jahresablesung", value: "Jahresablesung" },
    { label: "Zwischenablesung", value: "Zwischenablesung" },
    { label: "Preisänderung", value: "Preisänderung" },
];
const emit = defineEmits(["change"]);
const onChange = (value) => emit("change", value);
defineProps({
    selectedReason: {
        type: String,
        default: "",
    },
    forId: {
        type: String,
        required: true,
    },
});
</script>

<template>
    <form-row :top="0.5" :required="true" label="Ablesegrund" :for-id="forId">
        <template #input>
            <form-select
                :options="options"
                :selected="selectedReason"
                :for-id="forId"
                @change="onChange"
            />
        </template>
        <template v-if="selectedReason === ''" #text
            ><form-error
                error-text="Bitte wählen Sie den Ablesegrund aus."
                :top="0.25"
        /></template>
    </form-row>
</template>
