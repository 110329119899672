/******************************************************************************
 * store for auth page
 *****************************************************************************/
import { defineStore } from "pinia";
import { useNavigationStore } from "./navigation";
import { useAppStore } from "./app";
import { useReadingStore } from "Stores/reading";
import { ERROR_MESSAGES } from "@/config";
import { isDevelopment } from "@/handlers/useEnvironment";
import { router } from "@/router";
import {
    metersContainDismountedRegister,
    applyDismountedReadingDates,
} from "@/handlers/useReading";

/**
 * @function store definition
 */
export const useAuthStore = defineStore("auth", {
    state: () => {
        return { customerNo: "", meterNo: "", error: "" };
    },
    actions: {
        /**
         * @function logout button: empty customerNo/UserNo, reset navigation
         */
        logout() {
            const navigationStore = useNavigationStore();
            const readingStore = useReadingStore();
            navigationStore.$reset();
            readingStore.$reset();
            this.$reset();
            window.sessionStorage.removeItem("ivu-netzableseportal-auth");
            window.sessionStorage.removeItem("ivu-netzableseportal-navigation");
            window.sessionStorage.removeItem("ivu-netzableseportal-reading");
            router.push({ name: "Auth" });
        },
        /**
         * @function login button
         */
        login(customerNo, meterNo) {
            const appStore = useAppStore();
            const readingStore = useReadingStore();
            const navigationStore = useNavigationStore();
            appStore.requesting = true;
            navigationStore.$reset();
            readingStore.$reset();
            this.error = "";
            window.axios
                .post("/api/auth", { customerNo, meterNo })
                .then((response) => {
                    if (response.data.error) {
                        this.error = response.data.error;
                        navigationStore.changeStatus("Auth", "error");
                        console.error(response.data);
                    } else {
                        isDevelopment && console.log(response.data);
                        // mutate reading store
                        readingStore.config = response.data.config;
                        readingStore.customers = response.data.customers;
                        readingStore.meters = response.data.meters;
                        readingStore.readings = response.data.readings.map(
                            (reading) => {
                                reading.readingDate =
                                    readingStore.globalReadingDate;
                                reading.readingReason =
                                    readingStore.globalReadingReason;
                                return reading;
                            }
                        );
                        // check if there are dismounted meterRegisters
                        if (metersContainDismountedRegister()) {
                            readingStore.dateAndReasonType = "individual";
                            readingStore.readings = applyDismountedReadingDates(
                                readingStore.readings
                            );
                        }
                        // mutate navigation store
                        navigationStore.changeStatus("Auth", "done");
                        navigationStore.changeStatus("MeterReading", "");
                        router.push({ name: "MeterReading" });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    switch (err.response?.status) {
                        case 429:
                            this.error = ERROR_MESSAGES.error429;
                            console.error(err);
                            break;
                        case 500:
                        case 504:
                            this.error = ERROR_MESSAGES.error500;
                            console.error(err);
                            break;
                    }
                })
                .finally(() => {
                    appStore.requesting = false;
                });
        },
        /**
         * @function auth via hash data
         * @param {Object} authData
         * @param {Object} loginData
         */
        applyAuthDataFromHash(authData, loginData) {
            isDevelopment && console.log("applying auth data from hash");
            const navigationStore = useNavigationStore();
            const readingStore = useReadingStore();
            readingStore.config = authData.config;
            readingStore.customers = authData.customers;
            readingStore.meters = authData.meters;
            readingStore.readings = authData.readings.map((reading) => {
                reading.readingDate = readingStore.globalReadingDate;
                reading.readingReason = readingStore.globalReadingReason;
                return reading;
            });
            // check if there are dismounted meterRegisters
            if (metersContainDismountedRegister()) {
                readingStore.dateAndReasonType = "individual";
                readingStore.readings = applyDismountedReadingDates(
                    readingStore.readings
                );
            }
            if (loginData) {
                this.customerNo = loginData.customer;
                this.meterNo = loginData.meter;
            }
            navigationStore.changeStatus("Auth", "done");
            navigationStore.changeStatus("MeterReading", "");
            window.authedState = undefined;
            window.loginData = undefined;
        },
    },
    persist: {
        key: "ivu-netzableseportal-auth",
        storage: sessionStorage,
    },
});
