<script setup>
/******************************************************************************
 * PageComponent: Date and reason type ["individual" || "global"]
 *****************************************************************************/
import FormRow from "Components/Form/FormRow.vue";
import FormRadioGroup from "Components/Form/FormRadioGroup.vue";
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const radioButtons = [
    {
        value: "global",
        label: "Global für alle Zähler angeben",
        checked: data.dateAndReasonType === "global",
    },
    {
        value: "individual",
        label: "Individuell pro Zähler angeben",
        checked: data.dateAndReasonType === "individual",
    },
];
/**
 * @function change event handler for dateAndReasonType change
 * @param {Event} ev
 */
const changed = (ev) => {
    if (ev.target.value === "global") data.setDateAndReasonTypGlobal();
    if (ev.target.value === "individual") data.setDateAndReasonTypIndividual();
};
</script>

<template>
    <form-row
        :required="true"
        label="Ablesedatum und Ablesegrund"
        for-id="dateAndReasonType"
    >
        <template #input>
            <form-radio-group
                name="dateAndReasonType"
                :radio-buttons="radioButtons"
                @change="changed"
            />
        </template>
    </form-row>
</template>
