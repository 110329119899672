<script setup>
/******************************************************************************
 * PageComponent: Confirm > MeterRegisterData
 *****************************************************************************/
import { useReadingStore } from "Stores/reading";
import { formatReadingValue } from "@/handlers/useFormatting";
import { getConsumption } from "@/handlers/useReading";
const data = useReadingStore();
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
    meterNumber: {
        type: String,
        required: true,
    },
});
const meterData = data.getMeterData(props.meterNumber);
const image = data.getImageProps(props.meterRegisterId);
</script>

<template>
    <div class="meter-register-data">
        <ul>
            <li>Ablesegrund:</li>
            <li v-if="data.dateAndReasonType === 'global'">
                {{ data.globalReadingReason }}
            </li>
            <li v-else>{{ data.getIndividualReadingReason(meterNumber) }}</li>
            <li>Ablesedatum:</li>
            <li v-if="data.dateAndReasonType === 'global'">
                {{ data.globalReadingDate }}
            </li>
            <li v-else>{{ data.getIndividualReadingDate(meterNumber) }}</li>
            <li>Gemeldeter Zählerstand:</li>
            <li>
                {{
                    formatReadingValue(
                        parseFloat(data.getReadingValue(meterRegisterId))
                    )
                }}
                {{ meterData.meterUnit }}
                <span v-if="getConsumption(meterRegisterId) > 0">
                    (Verbrauch seit dem letzten Zählerstand:
                    {{ formatReadingValue(getConsumption(meterRegisterId)) }}
                    {{ meterData.meterUnit }})
                </span>
            </li>
            <li v-if="data.getImplausibleReason(meterRegisterId)">
                Grund für unplausiblen Zählerstand:
            </li>
            <li v-if="data.getImplausibleReason(meterRegisterId)">
                {{ data.getImplausibleReason(meterRegisterId) }}
            </li>
            <li v-if="data.getImplausibleReasonOther(meterRegisterId)">
                Sonstiger Grund:
            </li>
            <li v-if="data.getImplausibleReasonOther(meterRegisterId)">
                {{ data.getImplausibleReasonOther(meterRegisterId) }}
            </li>
            <li v-if="image?.thumb">Bild des Zählerstands:</li>
            <li v-if="image?.thumb">
                <img
                    :src="image.thumb"
                    class="thumbnail"
                    alt="Vorschau des hochgeladenen Bildes"
                />
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
.meter-register-data {
    header h1 {
        margin: 0 0 #{get($sizes, "base") * 0.5} 0;

        font-size: #{get($sizes, "base") + 1px};
        font-weight: 400;
    }

    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;

        padding: 0;
        margin: 0;
        gap: #{get($sizes, "base") * 0.5};

        list-style: none;

        > li:nth-child(2n - 1) {
            font-weight: 400;
        }

        @include respond-to("medium") {
            grid-template-columns: 35% calc(65% - #{get($sizes, "base") * 0.5});

            gap: #{get($sizes, "base") * 1};
        }
    }

    .thumbnail {
        padding: #{get($sizes, "border") * 1};
        border: #{get($sizes, "border") * 1} solid get($client, "imageThumbBorderColor");

        border-radius: get($sizes, "radius");
    }
}
</style>
