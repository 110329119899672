/******************************************************************************
 * formatting helper
 *****************************************************************************/

/**
 * @function format number / string with thousands-separator: "1.248,42"
 * @param {Number|String} numberString
 * @returns {string}
 */
export const formatReadingValue = (numberString) => {
    if (typeof numberString === "number")
        numberString = numberString.toString();
    return numberString.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
