import { useReadingStore } from "Stores/reading";
import { isReadingPlausible } from "@/handlers/useReading";

/**
 * @function check if globalReadingDate and globalReadingReason have a value
 * @param {defineStore} data
 * @param {Array} errors
 * @returns {Array}
 */
const getReasonAndDateTypeGlobalErrors = (data, errors) => {
    if (data.globalReadingReason?.length === 0) {
        errors.push("Bitte wählen Sie den *globalen Ablesegrund* aus.");
    }
    if (data.globalReadingDate?.length === 0) {
        errors.push("Bitte wählen Sie das *globale Ablesedatum* aus.");
    }
    return errors;
};

/**
 * @function for each meter, check if readingDate and readingReason have a value
 * @param {defineStore} data
 * @param {Array} errors
 * @returns {Array}
 */
const getReasonAndDateTypeIndividualErrors = (data, errors) => {
    const meterNumbers = data.readings
        .map((meter) => meter.meterNumber)
        .filter((value, index, self) => self.indexOf(value) === index);
    meterNumbers.forEach((meterNumber) => {
        // we only care for the first register, since both registers are updated anyway.
        const reading = data.getReadingRegisters(meterNumber)[0];
        if (reading.readingReason?.length === 0)
            errors.push(
                `Bitte wählen Sie den *Ablesegrund* für Zähler *${reading.meterNumber}* aus.`
            );

        if (reading.readingDate?.length === 0)
            errors.push(
                `Bitte wählen Sie das *Ablesedatum* für Zähler *${reading.meterNumber}* aus.`
            );
    });
    return errors;
};

/**
 * @function no meterRegister can have an empty readingValue
 * @param {defineStore} data
 * @param {Array} errors
 * @returns {Array}
 */
const getAllMetersRequiredErrors = (data, errors) => {
    const readingsMissingValues = data.readings.filter(
        (reading) => reading.meterReadingValue.length === 0
    );
    if (readingsMissingValues.length > 0)
        errors.push(
            "Bitte geben Sie für *alle* Zähler / Zählwerke einen Zählerstand an."
        );
    return errors;
};

/**
 * @function there needs to be one meter where every register has a readingValue.length > 0
 * @param {defineStore} data
 * @param {Array} errors
 * @returns {Array}
 */
const getSingleReadingValueErrors = (data, errors) => {
    let error = true;
    const meterNumbers = data.readings
        .map((meter) => meter.meterNumber)
        .filter((value, index, self) => self.indexOf(value) === index);
    meterNumbers.forEach((meterNumber) => {
        // get all readings with this meterNumber
        const readings = data.readings.filter(
            (reading) => reading.meterNumber === meterNumber
        );
        // get all readings with this meterNumber that do have a readingValue
        const readingsWithValues = data.readings.filter(
            (reading) =>
                reading.meterNumber === meterNumber &&
                reading.meterReadingValue.length > 0
        );
        // if both are identical, all registers of this meter have a readingValue => no error.
        if (readings.length === readingsWithValues.length) error = false;
    });
    if (error) {
        errors.push(
            "Bitte geben Sie einen Zählerstand bei mindestens *einem Zähler vollständig* an. Bei mehreren Zählwerken muss für alle Zählwerke ein Zählerstand angegeben werden."
        );
    }
    return errors;
};

/**
 * @function if a meterRegister has a readingValue, all registers of that meter needs to have a readingValue
 * (no incomplete meter readings!)
 * @param {defineStore} data
 * @param {Array} errors
 * @returns {Array}
 */
const getMultiRegisterErrors = (data, errors) => {
    const meterNumbers = data.readings.map((meter) => meter.meterNumber);
    const metersWithMultipleRegisters = meterNumbers.filter(
        (item, index) => meterNumbers.indexOf(item) !== index
    );
    metersWithMultipleRegisters.forEach((meterNumber) => {
        // get all readings with this meterNumber
        const readings = data.readings.filter(
            (reading) => reading.meterNumber === meterNumber
        );
        const readingsWithoutValues = data.readings.filter(
            (reading) =>
                reading.meterNumber === meterNumber &&
                reading.meterReadingValue.length === 0
        );
        if (readingsWithoutValues.length !== readings.length) {
            readingsWithoutValues.forEach((reading) => {
                errors.push(
                    `Bitte geben Sie den Zählerstand für *Zähler ${reading.meterNumber}, Zählwerk ${reading.meterRegisterNumber}* an.`
                );
            });
        }
    });
    return errors;
};

/**
 * @function for all meterRegisters with readingValue - if readingValue is implausible,
 * implausibleReason must be set.
 * if implausibleReason === "Sonstiges", then implausibleReasonOther must be set.
 * @param {defineStore} data
 * @param {Array} errors
 * @returns {Array}
 */
const getImplausibleReasonErrors = (data, errors) => {
    const allMeteringNumbers = data.readings.map((meter) => meter.meterNumber);
    const meterRegisterIds = data.readings
        .filter((reading) => reading.meterReadingValue.length > 0)
        .map((meterRegister) => meterRegister.meterRegisterId);
    meterRegisterIds.forEach((meterRegisterId) => {
        const meterRegister = data.getMeterRegisterData(meterRegisterId);
        const implausibleReason = data.getImplausibleReason(meterRegisterId);
        const implausibleReasonOther =
            data.getImplausibleReasonOther(meterRegisterId);
        let meterRegisterAddon = "";
        if (
            allMeteringNumbers.filter(
                (meterNumber) => meterNumber === meterRegister.meterNumber
            ).length > 1
        ) {
            meterRegisterAddon = `, Zählwerk ${meterRegister.meterRegisterNumber}`;
        }
        // readingValue is implausible, but no reason has been selected.
        if (
            !isReadingPlausible(meterRegisterId) &&
            implausibleReason.length === 0
        ) {
            errors.push(
                `Bitte wählen Sie den *Grund für den unplausiblen Zählerstand* für Zähler *${meterRegister.meterNumber}${meterRegisterAddon}* aus.`
            );
        }
        // readingValue is implausible, implausibleReason is "OTHER", but implausibleReasonOther has not been set
        if (
            !isReadingPlausible(meterRegisterId) &&
            implausibleReason === "Sonstiges" &&
            implausibleReasonOther.length === 0
        ) {
            errors.push(
                `Bitte geben Sie den *Sonstigen Grund* für den unplausiblen Zählerstand für Zähler *${meterRegister.meterNumber}${meterRegisterAddon}* an.`
            );
        }
    });

    return errors;
};

/**
 * @function calculate error messages to show.
 * if errors.length > 0, submit will be disabled.
 * @returns {Array}
 */
export const getReadingErrors = () => {
    let errors = [];
    const data = useReadingStore();
    // const nav = useNavigationStore();

    // check rules:
    // 1) reasonAndDateType === global => globalReadingReason and globalReadingDate must have a length > 0
    if (data.dateAndReasonType === "global") {
        errors = getReasonAndDateTypeGlobalErrors(data, errors);
    }
    // 2) reasonAndDateType === individual => for each meter, readingReason and readingDate must have a length > 0
    else if (data.dateAndReasonType === "individual") {
        errors = getReasonAndDateTypeIndividualErrors(data, errors);
    }
    // 3) allMetersRequired === true: each meterRegister must have a readingValue with length > 0
    if (data.config.allMetersRequired) {
        errors = getAllMetersRequiredErrors(data, errors);
    }
    // 4) allMetersRequired === false: there needs to be one meter where every register has a readingValue.length > 0
    else {
        errors = getSingleReadingValueErrors(data, errors);
    }
    // 5) if a meterRegister has a readingValue, all registers of that meter needs to have a readingValue
    errors = getMultiRegisterErrors(data, errors);
    // 6) for all meterRegisters with readingValue:
    // a) if readingValue is implausible, implausibleReason must be set.
    // b) if implausibleReason === "Sonstiges", implausibleReasonOther must be set.
    errors = getImplausibleReasonErrors(data, errors);

    return errors;
};
