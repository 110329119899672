<script setup>
/******************************************************************************
 * Component: Form row
 *****************************************************************************/
import { useSlots } from "vue";
defineProps({
    top: {
        type: Number,
        default: 0,
    },
    bottom: {
        type: Number,
        default: 0,
    },
    required: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: "",
    },
    forId: {
        type: String,
        default: "",
    },
    labelCentered: {
        type: Boolean,
        default: false,
    },
});
const slots = useSlots();
</script>

<template>
    <div class="form-row">
        <div v-if="slots.texttop" class="text">
            <slot name="texttop" />
        </div>
        <label
            :class="{ empty: label?.length === 0, centered: labelCentered }"
            class="label"
            :for="forId"
        >
            <span v-if="label.length > 0">
                {{ label }}<span v-if="required" class="required"> *</span>:
            </span>
        </label>
        <div class="input">
            <slot name="input" />
        </div>
        <div v-if="slots.text" class="text">
            <slot name="text" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.form-row {
    --factor-top: v-bind(top);

    display: flex;
    flex-flow: column wrap;

    margin-top: calc(v-bind(top) * get($sizes, "base"));
    margin-bottom: calc(v-bind(bottom) * get($sizes, "base"));

    @include respond-to("medium") {
        flex-flow: row wrap;

        margin-top: calc(v-bind(top) * get($sizes, "base") * 2);
        margin-bottom: calc(v-bind(bottom) * get($sizes, "base") * 2);
    }
}

.label {
    padding-top: #{get($sizes, "base") * 0.4375};
    flex: 0 0 35%;

    cursor: pointer;

    font-weight: 400;

    &.empty {
        padding-top: 0;
    }

    &.centered {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        padding-top: 0;
    }

    @include respond-to("medium") {
        text-align: right;
    }

    .required {
        color: get($client, "formReqStarColor");

        font-weight: 900;
    }
}

.input {
    display: flex;
    position: relative;

    max-width: get($breakpoints, "small");
    margin: #{get($sizes, "base") * 0.25} 0 0 0;
    flex: 0 0 calc(65% - #{get($sizes, "base") * 0.25});

    @include respond-to("medium") {
        margin: 0 0 0 #{get($sizes, "base") * 1};
        flex: 0 0 calc(65% - #{get($sizes, "base") * 1});
    }
}

.text {
    max-width: get($breakpoints, "small");
    flex: 0 0 calc(65% - #{get($sizes, "base") * 0.25});

    @include respond-to("medium") {
        margin-left: calc(35% + #{get($sizes, "base")});
        flex: 0 0 calc(65% - #{get($sizes, "base") * 1});
    }
}
</style>
