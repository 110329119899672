/******************************************************************************
 * store for navigation status
 *****************************************************************************/
import { defineStore } from "pinia";
import { isDevelopment } from "@/handlers/useEnvironment";
import { routes } from "@/router/routes";

/**
 * @function store definition
 */
export const useNavigationStore = defineStore("navigation", {
    state: () => {
        return {
            sections: routes.map((page) => {
                return {
                    name: page.name,
                    status: page.meta.baseStatus,
                };
            }),
        };
    },
    getters: {
        /**
         * @function get the status of a specific section
         * @param state
         * @returns {function(*): *} => {String}
         */
        getSectionStatus(state) {
            return (sectionName) =>
                state.sections.find((section) => section.name === sectionName)
                    .status;
        },
    },
    actions: {
        /**
         * @function change navigation section status
         * @param {String} sectionName
         * @param {String} newStatus - "", "done", "locked", "error"
         */
        changeStatus(sectionName, newStatus) {
            isDevelopment &&
                console.log(
                    `changing status of "${sectionName}" section to "${newStatus}".`
                );
            this.sections = this.sections.map((section) => {
                if (section.name === sectionName) section.status = newStatus;
                return section;
            });
        },
    },
    persist: {
        key: "ivu-netzableseportal-navigation",
        storage: sessionStorage,
    },
});
