<script setup>
/******************************************************************************
 * Icon Component
 * these svg icons use a external svg sprite sheet
 * and reference the id in that file
 *****************************************************************************/
import { computed } from "vue";
const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    size: {
        type: Number, // [0..4]
        default: 2,
    },
});
const sizeClass = computed(() => {
    switch (props.size) {
        case 0:
            return "tiny";
        case 1:
            return "small";
        case 2:
        default:
            return "";
        case 3:
            return "large";
        case 4:
            return "xlarge";
    }
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        class="icon"
        :class="sizeClass"
    >
        <use :xlink:href="`#${name}`" />
    </svg>
</template>

<style lang="scss" scoped>
.icon {
    display: inline-block;

    width: #{get($sizes, "base") * 1.5};
    height: #{get($sizes, "base") * 1.5};
    flex: 0 0 #{get($sizes, "base") * 1.5};

    fill: currentcolor;

    vertical-align: middle;

    &.tiny {
        width: #{get($sizes, "base") * 1};
        height: #{get($sizes, "base") * 1};
        flex: 0 0 #{get($sizes, "base") * 1};
    }

    &.small {
        width: #{get($sizes, "base") * 1.25};
        height: #{get($sizes, "base") * 1.25};
        flex: 0 0 #{get($sizes, "base") * 1.25};
    }

    &.large {
        width: #{get($sizes, "base") * 2};
        height: #{get($sizes, "base") * 2};
        flex: 0 0 #{get($sizes, "base") * 2};
    }

    &.xlarge {
        width: #{get($sizes, "base") * 4};
        height: #{get($sizes, "base") * 4};
        flex: 0 0 #{get($sizes, "base") * 4};
    }
}
</style>
