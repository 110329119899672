<script setup>
/******************************************************************************
 * Component: Request (upload) progress
 *****************************************************************************/
const props = defineProps({
    percentage: {
        type: Number,
        required: true,
    },
});
const shrinkerWidth = () => {
    return {
        width: 100 - props.percentage + "%",
    };
};
</script>

<template>
    <div class="wrapper" title="Übertrage Daten zum Server, bitte warten ...">
        <div class="upload-progress">
            <div class="bar">
                <div class="value">{{ percentage }}%</div>
            </div>
            <div class="shrinker" :style="shrinkerWidth()" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
$height: 20px;

.wrapper {
    display: flex;
    align-items: center;

    height: #{get($sizes, "base") * 1.4 + 12px + 4px};
    flex-grow: 1;
}

.upload-progress {
    display: flex;
    position: relative;
    align-items: center;

    width: 100%;
    height: $height;
    padding: 2px;
    border: #{get($sizes, "border")} solid get($client, "inputBorderColor");

    background: palette("grey", "astral");
    border-radius: get($sizes, "radius");
    box-shadow: inset 2px 2px 0 get($client, "appBg"),
        inset -2px 2px 0 get($client, "appBg"),
        inset 2px -2px 0 get($client, "appBg"),
        inset -2px -2px 0 get($client, "appBg");
}

.bar {
    position: relative;

    width: 100%;
    height: 100%;

    background: linear-gradient(
        to right,
        palette("state", "error"),
        palette("state", "warning"),
        palette("state", "success")
    );
    border-radius: get($sizes, "radius");
}

.value {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    transform: translate(-50%, -50%);

    font-weight: 600;
    line-height: 1;
    text-shadow: 1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff;
}

.shrinker {
    position: absolute;
    top: get($sizes, "border");
    right: get($sizes, "border");
    bottom: get($sizes, "border");

    width: 100%;

    background: palette("grey", "astral");

    transition: width get($timings, "fast") linear;
}
</style>
