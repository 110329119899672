/******************************************************************************
 * Router setup
 *****************************************************************************/
import { createRouter, createWebHistory } from "vue-router";
import { isDevelopment } from "@/handlers/useEnvironment";
import { routes } from "./routes";
import { useNavigationStore } from "Stores/navigation";
import { useAuthStore } from "Stores/auth";

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

/**
 * @function dynamically determin transition name for "to" and "from" pages.
 * this feels more natural than always sliding to a fixed direction.
 */
router.afterEach((to, from) => {
    const routeList = routes.map((route) => route.name);
    const toPosition = routeList.indexOf(to.name);
    const fromPosition = routeList.indexOf(from.name);
    to.meta.transitionName = toPosition > fromPosition ? "right" : "left";
    from.meta.transitionName = toPosition < fromPosition ? "right" : "left";
    if (fromPosition === -1) to.meta.transitionName = "";
});

/**
 * @function global route navigation guard
 * check if the "to" route has status = "locked" and return false if so.
 */
router.beforeEach((to, from) => {
    if (window.authedState) {
        const auth = useAuthStore();
        auth.applyAuthDataFromHash(window.authedState, window.loginData);
    }
    const toStatus = useNavigationStore().getSectionStatus(to.name);
    isDevelopment &&
        console.log(`navigation from ${from.name} => ${to.name}@${toStatus}`);
    return toStatus !== "locked";
});

export { router };
