/******************************************************************************
 * vue-router Routes
 *****************************************************************************/
import PageAuth from "Pages/Auth/PageAuth.vue";
import PageMeterReading from "Pages/MeterReading/PageMeterReading.vue";
import PageConfirm from "Pages/Confirm/PageConfirm.vue";
import PageSuccess from "Pages/Success/PageSuccess.vue";
import NotFound from "Pages/404/NotFound.vue";

export const routes = [
    {
        name: "Auth",
        path: "/",
        component: PageAuth,
        meta: {
            icon: "auth",
            title: "Anmeldung",
            baseStatus: "",
        },
    },
    {
        name: "MeterReading",
        path: "/ablesung",
        component: PageMeterReading,
        meta: {
            icon: "meter",
            title: "Zählerstände",
            baseStatus: "locked",
        },
    },
    {
        name: "Confirm",
        path: "/zusammenfassung",
        component: PageConfirm,
        meta: {
            icon: "confirm",
            title: "Überprüfen",
            baseStatus: "locked",
        },
    },
    {
        name: "Success",
        path: "/erfolgreich",
        component: PageSuccess,
        meta: {
            icon: "success",
            title: "Fertig",
            baseStatus: "locked",
        },
    },
    {
        name: "NotFound",
        path: "/:pathMatch(.*)*",
        component: NotFound,
        meta: { baseStatus: "" },
    },
];
