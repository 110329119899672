<script setup>
/******************************************************************************
 * PageComponent: Confirm > Accept privacy conditions
 *****************************************************************************/
import FormRow from "Components/Form/FormRow.vue";
import FormCheckBox from "Components/Form/FormCheckBox.vue";
import FormError from "Components/Form/FormError.vue";
import { URLS } from "@/config";
import { ref } from "vue";
const accepted = ref(false);
const emit = defineEmits(["change"]);
const onChange = (value) => {
    accepted.value = value;
    emit("change", accepted.value);
};
</script>

<template>
    <form-row
        :top="0.5"
        label="Datenschutz"
        :required="true"
        :label-centered="true"
        for-id="acceptPrivacy"
    >
        <template v-if="!accepted" #texttop>
            <form-error
                error-text="Bitte akzeptieren Sie unsere Datenschutzerklärung."
                :bottom="0.2"
            />
        </template>
        <template #input>
            <form-check-box
                ref-id="acceptPrivacy"
                :checked-initially="false"
                @change="onChange"
            >
                Hiermit akzeptiere ich die
                <a :href="URLS.privacy" class="text-link" target="_blank"
                    >Datenschutzerklärung</a
                >.
            </form-check-box>
        </template>
    </form-row>
</template>
